import React from "react";

const EngageForChange = () => {
    return (
        <div className="mx-auto" style={{ maxWidth: "1560px" }}>
            <div className="headline-title efc">
                <span className="engage">Engage</span>
                <span className="for">for</span>
                <span className="change">Change</span>
            </div>
        </div>
    );
};

export default EngageForChange;
