import React, { useEffect } from "react";
import SEO from "../components/seo";
import MastHead from "../components/masthead";
import Footer from "../components/footer";

const Layout = ({ children }) => {
    useEffect(() => {
        var palette = ["red", "blue", "green", "purple"];
        var randomPalette = palette[Math.floor(Math.random() * palette.length)];

        var body = document.body;
        body.className = "";
        body.classList.add(randomPalette);
    });

    return (
        <>
            <SEO />
            <MastHead />
            <div>{children}</div>
            <Footer />
        </>
    );
};

export default Layout;
