import React from "react";
import { Helmet } from "react-helmet";
import favicon from "../images/favicon.ico";

const SEO = (props) => {
    return (
        <div className="application">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{props.title}</title>
                <link rel="icon" href={favicon} />
                <link rel="canonical" href={"https://projectchange.uea.ac.uk/" + props.canonical} />
            </Helmet>
        </div>
    );
};

export default SEO;
