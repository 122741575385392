import React from "react";
import TwitterSVG from "./twitter";
import FacebookSVG from "./facebook";
import InstagramSVG from "./instagram";

const Social = (props) => {
    return (
        <div className="social flex justify-end">
            <TwitterSVG twitterUrl={props.twitterUrl} />
            <FacebookSVG facebookUrl={props.facebookUrl} />
            <InstagramSVG instagramUrl={props.instagramUrl} />
        </div>
    );
};
export default Social;
