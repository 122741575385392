import React from "react";
import Social from "./social";
import ProjectLogo from "./projectlogo";
import UEALogo from "./uealogo";
import EngageForChange from "./engageForChange";
import WeAreTheFuture from "./weAreTheFuture";

const Footer = () => {
    return (
        <footer className="relative">
            <EngageForChange />
            <WeAreTheFuture />
            <div className="container xl:w-5/6 mx-auto flex flex-wrap p-4 sm:p-0 mt-32">
                <div className="w-full md:w-1/2 text-center sm:text-left">
                    <ProjectLogo classname="black" />
                    <br />
                    <UEALogo classname="white" />
                </div>
                <div className="w-full md:w-1/2 flex align-baseline justify-end">
                    <Social
                        twitterUrl="https://www.twitter.com/uniofeastanglia"
                        facebookUrl="https://www.facebook.com/ueaofficial"
                        instagramUrl="https://www.instagram.com/uniofeastanglia"
                    />
                </div>
                <div className="w-full text-center md:text-right my-6">
                    Copyright &copy; 2021 | University of East Anglia
                </div>
            </div>
        </footer>
    );
};

export default Footer;
