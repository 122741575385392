import React from "react";

const UEALogo = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="199.101"
            height="43.755"
            viewBox="0 0 199.101 43.755"
            className="uea-logo"
        >
            <g className="uea-logo--inner" transform="translate(0 34.723)">
                <g
                    id="Group_23"
                    data-name="Group 23"
                    transform="translate(0 -34.723)"
                    clip-path="url(#clip-path)"
                >
                    <g id="Group_17" data-name="Group 17" transform="translate(-0.001 0.73)">
                        <path
                            className={props.classname}
                            id="Path_10"
                            data-name="Path 10"
                            d="M4.235,7.951c-.455,0-.146-.014-.572-.014-5.276,0-9.235-3.832-9.235-14.563V-22.442c0-5.422,2.268-7.48,5.478-7.48v-.644H-16.281v.636c2.814.057,3.831,2.585,3.831,5.909V-8.432c0,11.747,5.318,17.051,14.96,17.051H4.234Z"
                            transform="translate(16.281 30.566)"
                            fill="#fff"
                        />
                    </g>
                    <g id="Group_18" data-name="Group 18" transform="translate(46.156 13.337)">
                        <path
                            className={props.classname}
                            id="Path_11"
                            data-name="Path 11"
                            d="M1.42,3.1C1.357-2.79.1-4.057-5.46-4.115V-4.7C.1-4.754,1.357-6.018,1.42-11.905h.523C2.009-6.018,3.26-4.758,8.822-4.7v.585C3.26-4.057,2.009-2.79,1.943,3.1H1.459Z"
                            transform="translate(5.46 11.905)"
                            fill="#fff"
                        />
                    </g>
                    <g id="Group_19" data-name="Group 19" transform="translate(23.192 0.728)">
                        <path
                            className={props.classname}
                            id="Path_12"
                            data-name="Path 12"
                            d="M0,7.952c5.033,0,7.248-3.613,7.248-8.718h0l-.006-22.2c0-5.214-2.471-6.948-5.468-6.948l0-.651h28.59l.011,8.2-.505,0c-.854-4.821-2.632-6.705-8.433-6.705H20.09c-5.455,0-6.527,1.84-6.527,7v4.8c0,6.259,2.417,6.515,6.972,6.515v.587c-4.555,0-6.972.525-6.972,6.845v2.054c0,7.742.924,8.258,7.077,8.258,3.639,0,8.075-.987,9.215-6.294l.519,0V8.616H0Z"
                            transform="translate(0 30.57)"
                            fill="#fff"
                        />
                    </g>
                    <g id="Group_20" data-name="Group 20" transform="translate(60.054 0.001)">
                        <path
                            className={props.classname}
                            id="Path_13"
                            data-name="Path 13"
                            d="M.761,8.1C5.786,8.1,7.055,4.669,4.778-.71l-7.7-20.362L-.7-31.144h.423S10.316-4.561,12.941.818c2.774,5.677,5.1,7.286,7.4,7.286V8.77H.761Z"
                            transform="translate(2.924 31.145)"
                            fill="#fff"
                        />
                    </g>
                    <g id="Group_21" data-name="Group 21" transform="translate(88.893 0.657)">
                        <path
                            className={props.classname + " mobile-hide"}
                            id="Path_14"
                            data-name="Path 14"
                            d="M1.166,3.389c-3.536,0-5.649-2.7-5.649-7.2V-13.03H-2.3v9.1C-2.3-.543-.853.979,1.208.979S4.661-.5,4.661-3.808V-13.03H6.837v9.083c0,4.592-2.118,7.336-5.671,7.336"
                            transform="translate(4.483 13.03)"
                            fill="#fff"
                        />
                    </g>
                    <g id="Group_22" data-name="Group 22" transform="translate(102.416 4.161)">
                        <path
                            className={props.classname + " mobile-hide"}
                            id="Path_15"
                            data-name="Path 15"
                            d="M1.858,2.59H-.266V-4.564c0-2.028-.8-3.1-2.178-3.1-1.442,0-2.577,1.221-2.577,3.241V2.59H-7.143V-9.7h2v1.734a3.342,3.342,0,0,1,3.12-1.991C.332-9.955,1.858-8.013,1.858-5Z"
                            transform="translate(7.143 9.955)"
                            fill="#fff"
                        />
                    </g>
                </g>
                <path
                    className={props.classname + " mobile-hide"}
                    id="Path_17"
                    data-name="Path 17"
                    d="M90.586-31.915H92.68v-2.272H90.586Zm0-2.272-.008,16.03H92.7V-30.444H90.578Z"
                    transform="translate(23.562 0.139)"
                    fill="#fff"
                />
                <g id="Group_24" data-name="Group 24" transform="translate(117.848 -30.305)">
                    <path
                        className={props.classname + " mobile-hide"}
                        id="Path_18"
                        data-name="Path 18"
                        d="M1.247,2.556H-.628L-4.794-9.825h2.247L.313-.616,3.19-9.825H5.4Z"
                        transform="translate(4.794 9.825)"
                        fill="#fff"
                    />
                </g>
                <g
                    id="Group_31"
                    data-name="Group 31"
                    transform="translate(0 -34.723)"
                    clip-path="url(#clip-path)"
                >
                    <g id="Group_25" data-name="Group 25" transform="translate(128.58 4.162)">
                        <path
                            className={props.classname + " mobile-hide"}
                            id="Path_19"
                            data-name="Path 19"
                            d="M1.065,2.647c-2.985,0-5.16-2.756-5.16-6.413s2.159-6.412,4.971-6.412,4.785,2.614,4.785,6.5c0,.168,0,.359-.019.64l-.009.268H-1.885C-1.647-.557-.355.547,1.1.547A3.559,3.559,0,0,0,3.942-1L4.027-1.1l.088.1L5.192.131l.092.1L5.2.348a4.778,4.778,0,0,1-4.14,2.3M-1.9-4.766H3.5C3.316-6.6,2.431-8.044.84-8.044-.671-8.044-1.72-6.55-1.9-4.766"
                            transform="translate(4.095 10.177)"
                            fill="#fff"
                        />
                    </g>
                    <g id="Group_26" data-name="Group 26" transform="translate(140.211 4.203)">
                        <path
                            className={props.classname + " mobile-hide"}
                            id="Path_20"
                            data-name="Path 20"
                            d="M.431,2.581H-1.656V-9.707H.382v2.634c.7-1.905,1.948-2.849,3.484-2.849.06,0,.295.011.295.011v2.642H3.924C2.008-7.268.431-5.684.431-2.35Z"
                            transform="translate(1.656 9.922)"
                            fill="#fff"
                        />
                    </g>
                    <g id="Group_27" data-name="Group 27" transform="translate(146.67 4.21)">
                        <path
                            className={props.classname + " mobile-hide"}
                            id="Path_21"
                            data-name="Path 21"
                            d="M.818,2.627A5.651,5.651,0,0,1-3.053.958L-3.145.872-2.19-.886l.1.093A4.376,4.376,0,0,0,1.031.583c1.14,0,1.812-.575,1.812-1.515,0-.985-.72-1.336-2.058-1.8-1.41-.488-3.337-1.2-3.337-3.745a3.575,3.575,0,0,1,3.7-3.628A5.356,5.356,0,0,1,4.586-8.883l.1.077L3.825-6.961l-.1-.086A4.52,4.52,0,0,0,1.063-8.056C.017-8.056-.611-7.474-.611-6.7c0,.969.582,1.2,2.314,1.763,1.905.626,3.075,1.783,3.075,3.778A3.684,3.684,0,0,1,.818,2.627"
                            transform="translate(3.145 10.1)"
                            fill="#fff"
                        />
                    </g>
                    <g id="Group_28" data-name="Group 28" transform="translate(167.6 4.417)">
                        <path
                            className={props.classname + " mobile-hide"}
                            id="Path_22"
                            data-name="Path 22"
                            d="M.571,3.317A5.287,5.287,0,0,1-2.194,2.278L-1.483.214a4.111,4.111,0,0,0,1.934.863c1.134,0,1.648-.75,2.06-2L-1.91-12.75h2.3L3.54-3.971,6.119-12.75H8.36L4.167-.16c-.8,2.235-1.98,3.477-3.55,3.477"
                            transform="translate(2.194 12.75)"
                            fill="#fff"
                        />
                    </g>
                    <g id="Group_29" data-name="Group 29" transform="translate(181.42 4.129)">
                        <path
                            className={props.classname + " mobile-hide"}
                            id="Path_23"
                            data-name="Path 23"
                            d="M1.093,2.647c-2.922,0-5.3-2.8-5.3-6.367,0-3.641,2.388-6.453,5.334-6.453,2.93,0,5.333,2.792,5.333,6.364,0,3.639-2.418,6.457-5.372,6.457m0-10.594c-1.642,0-3.135,1.579-3.135,4.137S-.533.4,1.132.4s3.162-1.58,3.162-4.124c0-2.338-1.385-4.223-3.2-4.223"
                            transform="translate(4.202 10.174)"
                            fill="#fff"
                        />
                    </g>
                    <g id="Group_30" data-name="Group 30" transform="translate(192.823 0.001)">
                        <path
                            className={props.classname + " mobile-hide"}
                            id="Path_24"
                            data-name="Path 24"
                            d="M.723,3.447H-1.429V-6.627H-2.781V-8.845h1.352v-.8c0-3,2.179-3.606,3.188-3.606a4.006,4.006,0,0,1,1.656.309L3.5-12.9v2.228l-.1-.053a2.988,2.988,0,0,0-1.2-.3c-1.066,0-1.482.631-1.482,1.644v.558H3.477v2.2H.723Z"
                            transform="translate(2.781 13.25)"
                            fill="#fff"
                        />
                    </g>
                </g>
                <g id="Group_32" data-name="Group 32" transform="translate(88.8 -10.783)">
                    <path
                        className={props.classname + " mobile-hide"}
                        id="Path_26"
                        data-name="Path 26"
                        d="M1.934,3.316h-9.37V-12.746H1.839v2.32H-5.226v4.174H.983v2.317H-5.226V1.02h7.16Z"
                        transform="translate(7.436 12.746)"
                        fill="#fff"
                    />
                </g>
                <g
                    id="Group_34"
                    data-name="Group 34"
                    transform="translate(0 -34.723)"
                    clip-path="url(#clip-path)"
                >
                    <g id="Group_33" data-name="Group 33" transform="translate(99.384 27.49)">
                        <path
                            className={props.classname + " mobile-hide"}
                            id="Path_27"
                            data-name="Path 27"
                            d="M.8,2.628A3.754,3.754,0,0,1-3.068-1.338a4.188,4.188,0,0,1,4.5-4.347,6.157,6.157,0,0,1,2.419.464V-5.4A2.221,2.221,0,0,0,1.538-7.889,5.832,5.832,0,0,0-1.755-6.776l-.12.081-.612-2.113.1-.077A7.324,7.324,0,0,1,1.579-10.1a4.124,4.124,0,0,1,3.3,1.363,5.552,5.552,0,0,1,1.1,3.632V2.409H4.121V.853A3.725,3.725,0,0,1,.8,2.628M-.916-1.4a1.829,1.829,0,0,0,2,1.925A2.764,2.764,0,0,0,3.9-2.412v-.672A4.464,4.464,0,0,0,1.661-3.6,2.293,2.293,0,0,0-.916-1.4"
                            transform="translate(3.068 10.103)"
                            fill="#fff"
                        />
                    </g>
                </g>
                <g id="Group_35" data-name="Group 35" transform="translate(129.18 -10.759)">
                    <path
                        className={props.classname + " mobile-hide"}
                        id="Path_29"
                        data-name="Path 29"
                        d="M2.871,3.311H.5L-.935-.748H-7.258L-8.695,3.311h-2.341l6.1-16.038h1.706ZM-1.75-3.066-4.1-9.384-6.449-3.057Z"
                        transform="translate(11.036 12.727)"
                        fill="#fff"
                    />
                </g>
                <g
                    id="Group_37"
                    data-name="Group 37"
                    transform="translate(0 -34.723)"
                    clip-path="url(#clip-path)"
                >
                    <g id="Group_36" data-name="Group 36" transform="translate(154.919 27.458)">
                        <path
                            className={props.classname + " mobile-hide"}
                            id="Path_30"
                            data-name="Path 30"
                            d="M1.041,3.364a6.834,6.834,0,0,1-4.533-1.7l-.092-.078.916-2.023.108.1a5.459,5.459,0,0,0,3.7,1.5c2.191,0,3.192-1.612,3.192-3.749v-.978a3.821,3.821,0,0,1-3.6,2.244C-1.589-1.317-4-3.472-4-7.078c0-3.685,2.411-5.855,4.741-5.855A4.177,4.177,0,0,1,4.453-10.8v-1.876H6.339V-2.629a6.716,6.716,0,0,1-1.29,4.352A5.133,5.133,0,0,1,1.041,3.364M1.025-10.69c-1.537,0-2.854,1.266-2.854,3.517S-.458-3.558,1.025-3.558A3.282,3.282,0,0,0,4.133-7.1c0-2.491-1.568-3.586-3.107-3.586"
                            transform="translate(4 12.933)"
                            fill="#fff"
                        />
                    </g>
                </g>
                <path
                    className={props.classname + " mobile-hide"}
                    id="Path_32"
                    data-name="Path 32"
                    d="M135.149.329h-2.122V-15.7h2.122Z"
                    transform="translate(34.604 4.949)"
                    fill="#fff"
                />
                <g
                    id="Group_41"
                    data-name="Group 41"
                    transform="translate(0 -34.723)"
                    clip-path="url(#clip-path)"
                >
                    <g id="Group_38" data-name="Group 38" transform="translate(160.547 1.018)">
                        <path
                            className={props.classname + " mobile-hide"}
                            id="Path_33"
                            data-name="Path 33"
                            d="M.9,3.285c-1.865,0-3-1.258-3-3.538V-6.972H-3.465V-9.218l1.336-.005v-3.4H.033v3.409H2.907v2.247H.033V-.847C.033.3.4.989,1.321.989A2.542,2.542,0,0,0,2.633.651L2.809.544,2.8,2.715l-.079.04A3.251,3.251,0,0,1,.9,3.285"
                            transform="translate(3.465 12.627)"
                            fill="#fff"
                        />
                    </g>
                    <g id="Group_39" data-name="Group 39" transform="translate(118.79 24.29)">
                        <path
                            className={props.classname + " mobile-hide"}
                            id="Path_34"
                            data-name="Path 34"
                            d="M.9,3.285c-1.868,0-3-1.258-3-3.538V-6.972H-3.466V-9.194H-2.14l0-3.433H.031v3.412H2.9v2.243H.031V-.849C.031.308.4.992,1.321.992A2.531,2.531,0,0,0,2.631.651l.178-.1,0,2.166-.092.04A3.249,3.249,0,0,1,.9,3.285"
                            transform="translate(3.466 12.627)"
                            fill="#fff"
                        />
                    </g>
                    <g id="Group_40" data-name="Group 40" transform="translate(144.197 27.465)">
                        <path
                            className={props.classname + " mobile-hide"}
                            id="Path_35"
                            data-name="Path 35"
                            d="M1.859,2.59H-.267V-4.568c0-2.024-.805-3.1-2.18-3.1-1.44,0-2.576,1.221-2.576,3.241V2.59H-7.145V-9.7h2v1.73A3.34,3.34,0,0,1-2.031-9.955C.33-9.955,1.859-8.013,1.859-5Z"
                            transform="translate(7.145 9.955)"
                            fill="#fff"
                        />
                    </g>
                </g>
                <path
                    className={props.classname + " mobile-hide"}
                    id="Path_37"
                    data-name="Path 37"
                    d="M124.3-31.911h2.1v-2.272h-2.1Zm0-2.272-.008,16.054h2.123V-30.44h-2.123Z"
                    transform="translate(32.331 0.14)"
                    fill="#fff"
                />
                <path
                    className={props.classname + " mobile-hide"}
                    id="Path_38"
                    data-name="Path 38"
                    d="M138.822.32H136.7V-11.971h2.122ZM138.807-13.4h-2.1v-2.272h2.1Z"
                    transform="translate(35.56 4.954)"
                    fill="#fff"
                />
                <g
                    id="Group_44"
                    data-name="Group 44"
                    transform="translate(0 -34.723)"
                    clip-path="url(#clip-path)"
                >
                    <g id="Group_42" data-name="Group 42" transform="translate(109.782 27.47)">
                        <path
                            className={props.classname + " mobile-hide"}
                            id="Path_39"
                            data-name="Path 39"
                            d="M.818,2.627A5.627,5.627,0,0,1-3.052.958L-3.145.872-2.19-.882l.1.093A4.378,4.378,0,0,0,1.03.583C2.174.583,2.844.01,2.844-.931c0-.985-.721-1.336-2.06-1.791-1.406-.489-3.336-1.2-3.336-3.75a3.579,3.579,0,0,1,3.7-3.628A5.346,5.346,0,0,1,4.585-8.883l.1.077L3.826-6.961l-.1-.086a4.558,4.558,0,0,0-2.66-1.009C.017-8.056-.611-7.47-.611-6.7c0,.965.582,1.2,2.315,1.763,1.9.626,3.073,1.783,3.073,3.774A3.682,3.682,0,0,1,.818,2.627"
                            transform="translate(3.145 10.1)"
                            fill="#fff"
                        />
                    </g>
                    <g id="Group_43" data-name="Group 43" transform="translate(176.211 27.539)">
                        <path
                            className={props.classname + " mobile-hide"}
                            id="Path_40"
                            data-name="Path 40"
                            d="M.8,2.628A3.753,3.753,0,0,1-3.069-1.334,4.19,4.19,0,0,1,1.437-5.682a6.253,6.253,0,0,1,2.419.461v-.171a2.221,2.221,0,0,0-2.319-2.5A5.85,5.85,0,0,0-1.755-6.776l-.12.081-.612-2.109.1-.081A7.346,7.346,0,0,1,1.58-10.1a4.13,4.13,0,0,1,3.3,1.367A5.545,5.545,0,0,1,5.983-5.1v7.48H4.121V.853A3.728,3.728,0,0,1,.8,2.628M-.919-1.394a1.833,1.833,0,0,0,2,1.925A2.767,2.767,0,0,0,3.9-2.412V-3.08A4.422,4.422,0,0,0,1.662-3.6,2.3,2.3,0,0,0-.919-1.394"
                            transform="translate(3.069 10.103)"
                            fill="#fff"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default UEALogo;
