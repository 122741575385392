import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";
export default function Navigation() {
    function toggleMenu() {
        var menu = document.getElementById("menu-items");
        var masthead = document.getElementById("mastHead");

        if (menu.classList.contains("is-active")) {
            menu.classList.remove("is-active");
            masthead.classList.remove("menu-open");
        } else {
            menu.classList.add("is-active");
            masthead.classList.add("menu-open");
        }
    }

    function mobileOpen(e) {
        var click = e.target.parentNode;

        if (click.classList.contains("is-open")) {
            click.classList.remove("is-open");
            toggleMenu();
        } else {
            e.preventDefault();
            click.classList.add("is-open");
        }
    }
    function checkCurrent(e) {
        var isCurrent = e.target.ariaCurrent;
        var isHash = e.target.hash.length;
        var click = e.target.parentNode;
        console.log(isHash);
        if (
            (isHash !== "null" && !click.classList.contains("is-open")) ||
            (isCurrent === "page" && !click.classList.contains("is-open"))
        ) {
            toggleMenu();
            console.log("toggle should trigger");
        } else {
            console.log("nothing");
        }
    }
    return (
        <StaticQuery
            query={graphql`
                query NavQuery {
                    allSanityNav {
                        edges {
                            node {
                                navItems {
                                    text
                                    navItemUrl {
                                        linkUrl
                                    }
                                    children {
                                        navItemUrl {
                                            linkUrl
                                        }
                                        text
                                    }
                                }
                            }
                        }
                    }
                }
            `}
            render={(data) => (
                <nav role="navigation">
                    <button id="toggle" onClick={toggleMenu}>
                        Menu
                    </button>
                    <ul id="desktop-menu" className="desktop">
                        {data.allSanityNav.edges.map((e) =>
                            e.node.navItems.map((navItem) =>
                                navItem.children.length > 0 ? (
                                    <li className="dropdown">
                                        <Link to={navItem.navItemUrl.linkUrl}>{navItem.text}</Link>
                                        <ul>
                                            {navItem.children.map((childNavItem) => (
                                                <li>
                                                    <Link
                                                        to={childNavItem.navItemUrl.linkUrl}
                                                        onClick={checkCurrent}
                                                    >
                                                        {childNavItem.text}
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </li>
                                ) : (
                                    <li>
                                        <Link to={navItem.navItemUrl.linkUrl}>{navItem.text}</Link>
                                    </li>
                                )
                            )
                        )}
                    </ul>
                    <ul id="menu-items" className="mobile">
                        {data.allSanityNav.edges.map((e) =>
                            e.node.navItems.map((navItem) =>
                                navItem.children.length > 0 ? (
                                    <li className="dropdown">
                                        <Link to={navItem.navItemUrl.linkUrl} onClick={mobileOpen}>
                                            {navItem.text}
                                        </Link>
                                        <ul>
                                            {navItem.children.map((childNavItem) => (
                                                <li>
                                                    <Link
                                                        to={childNavItem.navItemUrl.linkUrl}
                                                        onClick={checkCurrent}
                                                    >
                                                        {childNavItem.text}
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </li>
                                ) : (
                                    <li>
                                        <Link to={navItem.navItemUrl.linkUrl}>{navItem.text}</Link>
                                    </li>
                                )
                            )
                        )}
                    </ul>
                </nav>
            )}
        />
    );
}
