import React from "react";

const WeAreTheFuture = () => {
    return (
        <div className="title hftf">
            <span className="impact-title">We</span>
            <span className="impact-title">Are</span>
            <span className="impact-title">the</span>
            <span className="impact-title">hope</span>
            <span className="impact-title">for</span>
            <span className="impact-title">the</span>
            <span className="impact-title">future</span>
        </div>
    );
};

export default WeAreTheFuture;
