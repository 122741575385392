import React from "react";
import ProjectLogo from "./projectlogo";
import UEALogo from "./uealogo";
import Navigation from "./navigation";

const MastHead = (props) => {
    return (
        <div id="mastHead" className="mastHead">
            <div className="container xl:w-5/6 mx-auto relative">
                <div className="row w-full flex flexwrap">
                    <div className={props.page + " logo"}>
                        <ProjectLogo />
                    </div>
                    <Navigation />
                    <a
                        href="https://www.uea.ac.uk/climate/climate-of-change"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <UEALogo />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default MastHead;
